.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(135deg, rgba(69, 104, 220, 0.6), rgb(176, 106, 179)), url('../public/images/midjourney-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  font-family: "Roboto", sans-serif;
}

.header {
  font-size: 4rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.subheader {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
  max-width: 1100px;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 200px;
  border: 2px dashed #b06ab3;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background: rgba(221, 221, 221, 0.5)
}

.instructions {
  font-size: 1.25rem;
  color: black;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  text-align: center;
}